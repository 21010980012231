import React from "react";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import thunk from "redux-thunk";

import App from "./App/index";
import * as serviceWorker from "./serviceWorker";
import reducer from "./store/reducer";
import config from "./config";

import "./assets/scss/style.scss";

const store = createStore(reducer, applyMiddleware(thunk));

const app = (
  <Provider store={store}>
    <BrowserRouter basename={config.basename}>
      <App />
    </BrowserRouter>
  </Provider>
);

window.$SITE_URL = window.location.origin;
window.$SITE_URL = window.$SITE_URL + "/";
if (window.$SITE_URL == "http://localhost:3000/")
  window.$SITE_URL = "http://localhost/m2m_web/";

ReactDOM.render(app, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
